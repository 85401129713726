import styled from "styled-components";

interface HeaderProps {}

const Root = styled.div`
  justify-content: center;
  display: flex;
  margin-top: 10px;
  img {
    width: 140px;
  }
`;

const Header: React.FC<HeaderProps> = ({}) => {
  return (
    <Root>
      <img src="https://uploads-ssl.webflow.com/63fc7a761662905fbc4490c2/63fc7c3f78a8c87ef155686b_logo.svg" />
    </Root>
  );
};

export default Header;
