import { create } from "zustand";

interface QuizResult {
  id: string;
  title: string;
  description: string;
  image: string;
}

interface QuizStep {
  id: string;
  stepText: string;
  questions: QuizQuestion[];
  image?: string;
}

export interface QuizQuestion {
  id: string;
  text: string;
  matchResultIds: string[];
  image?: string;
}

interface QuizData {
  title: string;
  description: string;
  results: QuizResult[];
  steps: QuizStep[];
}

export interface GameState {
  quizData?: QuizData;
  activeStepIndex: number;
  answers: string[];
  addAnswer: (value: string[]) => void;
  setQuizData: (value: QuizData) => void;
  setActiveStepIndex: (value: number) => void;
  setRestart: () => void;
}

export const useGameStore = create<GameState>((set) => ({
  quizData: undefined,
  activeStepIndex: 0,
  answers: [],
  setRestart: () => set(() => ({ activeStepIndex: 0, answers: [] })),
  addAnswer: (value: string[]) =>
    set((state) => ({
      answers: [...state.answers, ...value],
      activeStepIndex: state.activeStepIndex + 1,
    })),
  setQuizData: (value: QuizData) => set(() => ({ quizData: value })),
  setActiveStepIndex: (value: number) => set(() => ({ activeStepIndex: value })),
}));
