import { useEffect, useState } from "react";
import styled from "styled-components";
import { useGameStore } from "../store";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/loading";

interface LoadingScreenProps {}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

const LoadingScreen: React.FC<LoadingScreenProps> = ({}) => {
  const store = useGameStore();
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    fetch("https://europe-west1-quiz-eu-prod.cloudfunctions.net/getQuiz?id=" + params.id)
      .then((response) => response.json())
      .then((data) => {
        const json = data?.items[0]["json-string"];
        const josnData = JSON.parse(json);
        setTimeout(() => {
          store.setQuizData(josnData);
          navigate("/game");
        }, 500);
      });
  }, []);

  return (
    <Root>
      <Loading />
    </Root>
  );
};

export default LoadingScreen;
