import styled from "styled-components";

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

const Root = styled.div<{ disabled: boolean }>`
  border-radius: 40px;
  background: ${(props) =>
    props.disabled
      ? "rgba(255, 255, 255, 0.303)"
      : "linear-gradient(180deg, #f36627 0%, #d8581d 100%);"};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  color: ${(props) => (props.disabled ? "rgba(255, 255, 255, 0.2)" : "#fff")};
  text-align: center;
  font-size: 18px;
  padding: 15px 45px;
  text-transform: uppercase;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  cursor: pointer;
  font-weight: bold;
  :active {
    opacity: 0.9;
  }
  :hover {
    opacity: 0.9;
  }
`;

const Button: React.FC<ButtonProps> = ({ children, onClick, disabled = false }) => {
  return (
    <Root onClick={disabled ? () => {} : onClick} disabled={disabled}>
      {children}
    </Root>
  );
};

export default Button;
