import { Flex } from "./flex";
import LoadingSpinner from "./loading-spinner";

interface LoadingProps {}

const Loading: React.FC<LoadingProps> = ({}) => {
  return (
    <Flex alignItems="center" py="50px" flexDirection="column">
      <LoadingSpinner />
    </Flex>
  );
};

export default Loading;
