import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Layout from "../components/layout";
import ButtonOption from "../components/button-option";
import { Grid } from "../components/flex";
import Headline from "../components/headline";
import Footer from "../components/footer";
import Header from "../components/header";
import Loading from "../components/loading";

interface StartScreenProps {}

const StartScreen: React.FC<StartScreenProps> = ({}) => {
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    const url = `https://europe-west1-quiz-eu-prod.cloudfunctions.net/getQuizzes`;
    const options = {
      method: "GET",
      headers: {
        accept: "application/json",
      },
    };

    fetch(url, options)
      .then((res) => res.json())
      .then((json) => setData(json))
      .catch((err) => console.error("error:" + err));
  }, []);
  console.log(data);
  return (
    <Layout>
      <Header />
      <Headline>Welches Quiz möchtest du spielen?</Headline>
      <Grid mt="25px">
        {data?.items?.map((item: any) => (
          <ButtonOption
            key={item._id}
            imageUrl={item["gif-url"]}
            label={item.name}
            onClick={() => navigate("/fetch-quiz/" + item._id)}
          />
        ))}
      </Grid>
      {!data?.items && <Loading />}
      <Footer />
    </Layout>
  );
};

export default StartScreen;
