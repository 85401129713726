import ReactDOM from "react-dom/client";
import styled from "styled-components";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import GameScreen from "./pages/game";
import ResultScreen from "./pages/result";
import StartScreen from "./pages/start";
import LoadingScreen from "./pages/loading";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <Routes>
      <Route element={<StartScreen />} path="/" />
      <Route element={<LoadingScreen />} path="/fetch-quiz/:id" />
      <Route element={<GameScreen />} path="/quiz/:id" />
      <Route element={<GameScreen />} path="/game" />
      <Route element={<ResultScreen />} path="/result" />
      <Route element={<StartScreen />} path="*" />
    </Routes>
  </BrowserRouter>
);
