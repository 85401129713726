import styled from "styled-components";
import Layout from "../components/layout";
import Progress from "../components/progress";
import { Box, Grid } from "../components/flex";
import ButtonOption from "../components/button-option";
import Button from "../components/button";
import { QuizQuestion, useGameStore } from "../store";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/footer";

interface GameScreenProps {}

const HeaderImage = styled.img`
  border-radius: 10px;
  width: 100%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
`;

const Headline = styled.div`
  text-align: center;
  font-size: 22px;
  color: #fff;
  margin-top: 25px;
`;

const ProgressArea = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: #0d1324;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
`;

const GameScreen: React.FC<GameScreenProps> = ({}) => {
  const store = useGameStore();
  const [selectedQuestion, setQuestion] = useState<QuizQuestion | null>(null);
  const navigate = useNavigate();
  const quizData = store.quizData;
  const stepIndex = store.activeStepIndex || 0;
  const steps = quizData?.steps || [];
  const activeStep = steps[stepIndex];
  const progress = (stepIndex / steps.length) * 100;

  useEffect(() => {
    if (progress >= 100) {
      navigate("/result");
    }
    if (!quizData) {
      navigate("/");
    }
  }, [progress, quizData]);

  return (
    <Layout>
      {activeStep?.image && (
        <Box mt="25px">
          <HeaderImage src={activeStep?.image} />
        </Box>
      )}
      <Headline>{activeStep?.stepText || "ENDE"}</Headline>
      <Grid mt="25px" mb="25px">
        {activeStep?.questions.map((question) => (
          <ButtonOption
            key={question.id}
            imageUrl={question.image}
            onClick={() => setQuestion(question)}
            label={question.text}
            isGray={(selectedQuestion && selectedQuestion?.id !== question.id) || false}
            isActive={selectedQuestion?.id === question.id || false}
          />
        ))}
      </Grid>
      <ProgressArea>
        <Box px="5px">
          <Progress value={progress} />
        </Box>
        <Box mt="15px" mb="15px">
          <Button
            disabled={!selectedQuestion}
            onClick={() => {
              if (selectedQuestion) {
                store.addAnswer(selectedQuestion.matchResultIds);
                setQuestion(null);
              }
            }}
          >
            {selectedQuestion ? "Weiter" : "Antwort auswählen"}
          </Button>
        </Box>
      </ProgressArea>
      <Footer />
    </Layout>
  );
};

export default GameScreen;
