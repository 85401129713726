import styled from "styled-components";

interface BoxInterface {
  p?: string;
  m?: string;
  px?: string;
  py?: string;
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
  mt?: string;
  ml?: string;
  mb?: string;
  mr?: string;
  width?: string;
  flexShrink?: number;
  marker?: boolean;
  flex?: number;
}

interface FlexInterface extends BoxInterface {
  justifyContent?: string;
  flexDirection?: string;
  alignItems?: string;
}

export const Box = styled.div<BoxInterface>`
  padding-top: ${({ pt = null, py = null, p = "0" }) => String(pt || py || p)};
  padding-bottom: ${({ pb = null, py = null, p = "0" }) => String(pb || py || p)};
  padding-left: ${({ pl = null, px = null, p = "0" }) => String(pl || px || p)};
  padding-right: ${({ pr = null, px = null, p = "0" }) => String(pr || px || p)};
  margin-top: ${({ mt = null, m = "0" }) => String(mt || m)};
  margin-left: ${({ ml = null, m = "0" }) => String(ml || m)};
  margin-bottom: ${({ mb = null, m = "0" }) => String(mb || m)};
  margin-right: ${({ mr = null, m = "0" }) => String(mr || m)};
  width: ${({ width = "auto" }) => String(width)};
  background: ${({ bg }: any) => bg || "transparent"};
  flex-shrink: ${({ flexShrink = 0 }) => flexShrink};
  ${({ marker = false }) => (marker ? "background: rgba(255,0,0,0.1)" : "")};
`;

export const Flex = styled(Box)<FlexInterface>`
  justify-content: ${({ justifyContent = "flex-start" }) => String(justifyContent)};
  align-items: ${({ alignItems = "flex-start" }) => String(alignItems)};
  flex-direction: ${({ flexDirection = "row" }) => String(flexDirection)};
  display: flex;
`;

interface GridInterface extends BoxInterface {
  gap?: string;
  justifyContent?: string;
  flexDirection?: string;
  alignItems?: string;
}

export const Grid = styled(Box)<GridInterface>`
  display: grid;
  box-sizing: border-box;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ gap = "10px" }) => String(gap)};
  flex-direction: ${({ flexDirection = "row" }) => String(flexDirection)};
  grid-auto-rows: 1fr;
`;
