import styled from "styled-components";

const Headline = styled.div`
  text-align: center;
  font-size: 22px;
  color: #fff;
  margin-top: 25px;
  line-height: 1.5;
`;

export default Headline;
