interface Result {
  value: string;
  count: number;
}

const getResult = (arr: string[]) => {
  var count = arr.reduce(function (acc: any, curr) {
    if (typeof acc[curr] == "undefined") {
      acc[curr] = 1;
    } else {
      acc[curr] += 1;
    }

    return acc;
  }, {});

  var result = Object.keys(count).map(function (key) {
    return {
      value: key,
      count: count[key],
    };
  });

  return result.sort(function (a, b) {
    return b.count - a.count;
  });
};

export default getResult;
