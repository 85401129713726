import styled from "styled-components";

interface ProgressProps {
  value: number;
}

const Root = styled.div`
  background-color: rgba(255, 255, 255, 0.126);
  border-radius: 15px;
  height: 10px;
  overflow: hidden;
  padding: 5px;
`;
const Bar = styled.div<{ width: number }>`
  width: ${(props) => props.width}%;
  height: 100%;
  background: linear-gradient(90deg, #64a100 0%, #9eff02 100%);
  transition: width 0.5s ease-in-out;
  border-radius: 10px;
`;

const Text = styled.div`
  font-size: 12px;
  color: #fff;
  text-align: right;
  position: absolute;
  right: 25px;
  top: 12px;
`;

const Progress: React.FC<ProgressProps> = ({ value }) => {
  return (
    <Root>
      <Bar width={value} />
      <Text>{value}%</Text>
    </Root>
  );
};

export default Progress;
