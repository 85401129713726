import ConfettiExplosion from "react-confetti-explosion";
import Layout from "../components/layout";
import { Box, Flex } from "../components/flex";
import styled from "styled-components";
import Button from "../components/button";
import { useNavigate } from "react-router-dom";
import { useGameStore } from "../store";
import getResult from "../utils/get-result";
import Footer from "../components/footer";

interface ResultScreenProps {}

const HeaderImage = styled.img`
  border-radius: 10px;
  width: 80%;
  margin: 0 auto;
  border: 2px solid #ffffff52;
`;

const Headline = styled.div`
  font-size: 30px;
  color: #8bff00;
  margin-top: 25px;
  font-weight: 600;
  text-align: center;
  line-height: 1.5;
  small {
    font-weight: 200;
    font-size: 18px;
    color: #fff;
  }
`;
const Text = styled.div`
  font-size: 18px;
  line-height: 1.5;
  font-weight: 100;
  color: #fff;
  margin-top: 25px;
  text-align: center;
`;

const ResultScreen: React.FC<ResultScreenProps> = ({}) => {
  const store = useGameStore();
  const navigate = useNavigate();
  const results = getResult(store?.answers) || [];
  const result = store.quizData?.results?.find((r) => r.id === results[0].value);

  return (
    <Layout>
      <Flex mt="25px" justifyContent="center" alignItems="center" flexDirection="column">
        <ConfettiExplosion duration={2000} width={1000} particleCount={100} />
        <HeaderImage src={result?.image} />
      </Flex>
      <Headline>
        <small>🎉 Dein Ergebnis:</small>
        <br />
        {result?.title}
      </Headline>
      <Text>{result?.description}</Text>
      <Flex mt="25px" justifyContent="center">
        <Button
          onClick={() => {
            window.location.href = "https://www.snash.io/quizzes";
          }}
        >
          NEUES SPIEL
        </Button>
      </Flex>
      <Footer />
    </Layout>
  );
};

export default ResultScreen;
