import styled from "styled-components";
import { Flex } from "./flex";

interface FooterProps {}

const Root = styled.div`
  font-size: 12px;
  color: #ffffff61;
  padding: 15px;
  margin-top: 80px;
  text-align: center;
  a {
    color: #fff;
    text-decoration: none;
    margin: 0 5px;
  }
`;

const Footer: React.FC<FooterProps> = ({}) => {
  return (
    <Root>
      <Flex mb="5px" justifyContent="center">
        <a href="https://www.snash.io/legal/legal-notice>">Impressum</a>
        {" | "}
        <a href="https://www.snash.io/legal/privacy-policy">Datenschutz</a>
      </Flex>
      © Snash Game Studio (snash.io) 2016 - 2023
      <br />
      All rights reserved.
    </Root>
  );
};

export default Footer;
