import styled from "styled-components";
import { Box } from "./flex";

interface ButtonOptionProps {
  label: string;
  imageUrl?: string;
  onClick: () => void;
  isActive?: boolean;
  isGray?: boolean;
}

const Root = styled.div<{ isActive: boolean; isGray: boolean }>`
  transition: all 0.1s linear;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  border: 4px solid ${(props) => (props.isActive ? "#8bff00" : "rgba(255, 255, 255, 0.303)")};
  padding: 5px;
  color: #fff;
  font-size: 16px;
  line-height: 1.2;
  opacity: ${(props) => (props.isGray ? 0.6 : 1)};
  outline: none;
  :active {
    border-color: #63a1003b;
  }
`;

const HeaderImage = styled.div<{ src: string }>`
  border-radius: 10px;
  height: 80px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${(props) => props.src});
  margin-bottom: 10px;
`;

const ButtonOption: React.FC<ButtonOptionProps> = ({
  label,
  imageUrl,
  onClick,
  isActive,
  isGray,
}) => {
  return (
    <Root onClick={onClick} isGray={isGray || false} isActive={isActive || false}>
      {imageUrl && <HeaderImage src={imageUrl} />}
      <Box p="5px">{label}</Box>
    </Root>
  );
};

export default ButtonOption;
